import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const TestamentosPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Testamentos" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Testamentos"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="El fundamento legal lo encontramos en el libro tercero artículo 935 del Código Civil de Guatemala."
      >
        <p>
        El testamento es un acto puramente personal y de carácter revocable, por medio del cual
        una persona dispone en todo o en parte de sus bienes, para después de su muerte.
        </p>
        <p>
        <strong>¡¡¡Llámenos y haga una cita hoy!!!</strong>
        </p>
        <p>
        El Licenciado Erick Claveria con gusto le podrá dar asesoría legal si usted desea otorgar
        su testamento para disponer de los bienes que posee en Guatemala.
          </p>       
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default TestamentosPage

